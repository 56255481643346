import { Component, BaseComponent } from '@zento-lib/components';
import { currentStoreView } from 'core/lib/multistore';

import type { IANPC } from './ANPC.d';
import style from './style.scss';

/**
 * Footer absolute
 *
 * Renders only minimal information
 */
@Component({})
export class ANPC extends BaseComponent<IANPC, unknown> {
  render() {
    const anpc = this.extended.$config.zento.footerConfig.ANPC;
    const storeView = currentStoreView();

    return anpc.enabled && storeView.i18n.defaultCountry === 'RO' ? (
      <div class={style.anpcContent}>
        <ul>
          <li class={style.anpcSal}>
            <a href={anpc.anpcSal} target='_blank' rel='noindex nofollow' />
          </li>
          <li class={style.anpcSol}>
            <a href={anpc.anpcSol} target='_blank' rel='noindex nofollow' />
          </li>
        </ul>
      </div>
    ) : null;
  }
}
