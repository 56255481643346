import { Component, BaseComponent, Prop, namespace, Watch } from '@zento-lib/components';
import { IVSFUser, KEY as UserKey } from 'theme/@types/vsf/stores/user';
import type { FooterData } from 'theme/stores/navigation/types';

import { CmsBlock } from '../../CmsBlock/CmsBlock';
import { Link } from '../../../atom/Link/Link';

import type { IFooterBlocks } from './Blocks.d';
import style from './style.scss';

const userStore = namespace<IVSFUser>(UserKey);

/**
 * Footer blocks
 *
 * Renders custom content information (like contact, mandatory links, etc.)
 */
@Component({})
export class FooterBlocks extends BaseComponent<IFooterBlocks, unknown> {
  @Prop({ type: Array, required: true })
  content: FooterData[];

  @userStore.State('current')
  protected currentUser: IVSFUser['state']['current'];

  @userStore.Getter('isLoggedIn')
  loggedIn: IVSFUser['state']['current'];

  @Watch('loggedIn')
  isLoggedIn() {
    const redirectObj = localStorage.getItem('footerRedirect');

    if (redirectObj) {
      this.$router.push(redirectObj);
    }

    localStorage.removeItem('footerRedirect');
  }

  goToAccount(url: string) {
    if (!this.currentUser) {
      this.$store.commit('myAccount/setDrawerActive', true);
      this.$store.commit('ui/setOverlay', true);
      this.$store.commit('ui/setAuthElem', 'login');
      localStorage.setItem('footerRedirect', url);
    }
  }

  /**
   * Determines footer navigation items
   */
  private get items() {
    return this.content.filter(Boolean).sort((a, b) => a.sortOrder - b.sortOrder);
  }

  render() {
    return (
      <ul class={style.footerLinks}>
        {this.items.map((link) => {
          if (link.target === 'category') {
            // category item
            return (
              <li class={style.footerLinkItem} key={'category-' + link.id}>
                <Link
                  to={link.url_path ? (link.url_path.indexOf('/') >= 0 ? link.url_path : '/' + link.url_path) : ''}
                  styleType='secondary'>
                  {link.label}
                </Link>
              </li>
            );
          } else if (link.target === 'cms_page') {
            // cms page item
            return (
              <li class={style.footerLinkItem} key={'cms-page-' + link.id}>
                <Link to={'/i/' + link.url_path} styleType='secondary'>
                  {link.label}
                </Link>
              </li>
            );
          } else if (/(account)/g.test(link.target)) {
            // account item
            return (
              <li
                onClick_capture={() => this.goToAccount('/' + link.url_path)}
                class={style.footerLinkItem}
                key={'account-page-' + link.id}>
                <Link to={'/' + link.url_path} styleType='secondary'>
                  {link.label}
                </Link>
              </li>
            );
          } else if (link.target === 'blog') {
            // blog item
            return (
              <li class={style.footerLinkItem} key={'blog-page-' + link.id}>
                <Link to={'/blog'} styleType='secondary'>
                  {link.label}
                </Link>
              </li>
            );
          } else if (link.target === 'gdpr_popup') {
            // gdpr item
            return (
              <li
                onClick_capture={() => this.broadcast('cookie/open-popup')}
                class={style.footerLinkItem}
                key={'gdpr-page-' + link.id}>
                <span>{link.label}</span>
              </li>
            );
          } else if (link.target === 'cms_block') {
            // cms block item
            return (
              <li class={style.footerLinkItem} key={'cms-block-' + link.id}>
                <CmsBlock identifier={link.cmsBlock} class={style.footerCmsBlock} />
              </li>
            );
          } else if (link.target === 'external') {
            // external item
            return (
              <li class={style.footerLinkItem} key={'external-link-' + link.id}>
                <a href={link.url_path} rel='noopener' target='_blank'>
                  {link.label}
                </a>
              </li>
            );
          } else {
            console.debug('Unhandled footer item', link);

            return null;
          }
        })}
      </ul>
    );
  }
}
